//interfaces
import {  interfaceArchiveArray } from "../interfaces/project";

//styles
//--icons
import { CheckIcon, FileUploadIcon, InfoIcon, MoreIcon } from "../styles/project";
import { dateComplet, dateDay, pathFile } from "../../utils/utils";
import { InterfaceProject } from "../../interfaces/project";
import { TFunction } from "react-i18next";


        

export const dataTutor = (data: InterfaceProject, t: TFunction<"global", undefined>):interfaceArchiveArray =>  [
  
    {
        props: { width: "auto", textDecoration: "underline" },
        ComponentIcon: data.workFile ? CheckIcon : FileUploadIcon,
        ComponentAction: {
            element: InfoIcon,
            menuItems: [],
            info: {
                content: [
                    t("zProjects.data.tutor.info")
                ],
                title: t("zProjects.data.tutor.info-tutor-title"),
            },
        },
        text1: data.workFile ? pathFile(data.workFile.key) : "Informe de trabajo",
        text2: data.workFile
        ? t("zProjects.data.representative.upload") + dateComplet(data.workFile.date) + 
          (data.tutors && data.tutors.length > 0 && data.tutors[data.tutors.length - 1].firstName 
            ? t("zProjects.data.representative.by") + data.tutors[data.tutors.length - 1].firstName 
            : " - Usuario no existente")
        : t("zProjects.data.representative.term") + dateDay(data.announcement.limitDate) + t("zProjects.data.representative.days"),
  

        text3: data.workFile ? undefined : t("zProjects.data.representative.c-upload"),
        typeFile: 'work',
        data: data.workFile
    },
    {
        props: { width: "auto", textDecoration: "underline" },
        ComponentIcon: data.workReport ? CheckIcon : FileUploadIcon,
        ComponentAction: {
            element: InfoIcon,
            menuItems: [],
            info: {
                content: [
                    t("zProjects.data.tutor.iframe")
                ],
                title: t("zProjects.data.tutor.info-title-report"),
            },
        },
        text1: data.workReport ? pathFile(data.workReport.key) : t("zProjects.data.tutor.report-e-work"),
        text2: data.workReport
        ? t("zProjects.data.representative.upload") + dateComplet(data.workReport.date) + 
          (data.tutors && data.tutors.length > 0 && data.tutors[data.tutors.length - 1].firstName 
            ? t("zProjects.data.representative.by") + data.tutors[data.tutors.length - 1].firstName 
            : " - Usuario no existente")
        : t("zProjects.data.representative.term") + dateDay(data.announcement.limitDate) + t("zProjects.data.representative.days"),
    

        text3: data.workReport ? undefined : t("zProjects.data.representative.c-upload"),
        typeFile: 'report',
        data: data.workReport
        
    },
    {
        props: { width: "auto" },
        ComponentIcon: data.tutorReport ? CheckIcon : FileUploadIcon,
        ComponentAction: {
            element: data.tutorReport ? MoreIcon : InfoIcon,
            more:data.tutorReport? true:false,
            menuItems: data.tutorReport
                ? [
                      {
                          text: t("zProjects.data.tutor.rename"),
                          action: "update",
                      },
                      {
                          text: t("zProjects.data.tutor.delete"),
                          action: "Delete",
                      },
                  ]
                : [],
                info: data.tutorReport
                ? {}
                : {
                    content: [
                        t("zProjects.data.tutor.info-tutor-content"),
                    ],
                    title: t("zProjects.data.tutor.iframe-title"),
                },
       
               
        },
        text1: data.tutorReport ? pathFile(data.tutorReport.key) : t("zProjects.data.tutor.report-tutor"),
        text2: data.tutorReport
            ? t("zProjects.data.representative.upload") + dateComplet(data.tutorReport.date) + t("zProjects.data.representative.by") + (data.tutors && data.tutors[data.tutors.length - 1].firstName?t("zProjects.data.representative.by") + data.tutors[data.tutors.length - 1].firstName:'')
            : t("zProjects.data.representative.term") + dateDay(data.announcement.limitDate) + t("zProjects.data.representative.days"),
        text3: data.tutorReport
            ? undefined
            : t("zProjects.data.tutor.upload-file"),
        typeFile: 'tutor',
        data: data.tutorReport
    },
  
]