//React Modules
import { memo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
} from "@mui/material";

import {
  InterfaceTableBase,
  InterfaceColumnBase,
} from "../../interfaces/table";

//styles
import { NavbarLink } from "../../../styles/nav";

import {
  ButtonComponent,
  DoubleContainer,
  TitleWrapper,
  ReturnIco
} from "../../../styles/globalComponents";

//translate
import { useTranslation } from "react-i18next";

//Components

import { Loading } from "../../../components/loading";

//hooks
import { useAppDispatch } from "../../../hooks/redux";
import { useForm } from "../../../hooks/form";

//api
import {
  useGetProjectsEvaluateQuery,
  usePutProjectEvaluateMutation,
} from "../../api/project";

import { TiEditIcon } from "../../styles/file";
import {
  InterfaceDataEvaluated,
  InterfaceEvaluationsResponse,
} from "../../interfaces/evaluations";

//hoks
import { useAppSelector } from "../../../hooks/redux";

//slices
import { selectUser } from "../../../zUsers/slices/auth";
import { InterfaceRoleUser } from "../../../interfaces/user";
import {
  mediaTotal,
  statusEvaluation,
  verifyPublish,
} from "../../utils/evaluate";
import {
  InteraceRequestEvaluate,
  InterfaceScore,
} from "../../interfaces/score";

//hooks
import { activeAlertAction } from "../../../slices/alerts";

import {
  completedAction,
  editAction,
  saveAction,
  selectEvaluate,
} from "../../slides/evaluate";
import { InterfacePropsEvaluated } from "../../../interfaces/evaluate";
import { InterfaceEvaluation } from "../../../interfaces/project";

export const TableEvaluationsForm = memo(
  ({
    title,
    colum,
    select,
    button,
    props,
  }: InterfaceTableBase): JSX.Element => {
    //form hooks
    const [t, i18n] = useTranslation("global");

    const user = useAppSelector(selectUser);

    const roleUser = user.role as InterfaceRoleUser;
    let userPublish: false | InterfaceEvaluationsResponse = false;

    const { idProjectEvaluate } = useParams();

    const navigate = useNavigate();
    //events
    const dispatch = useAppDispatch();
    //apis
    const [putEvaluated, { isLoading: isLoadingEvaluate }] =
      usePutProjectEvaluateMutation();

    const {
      isLoading,
      currentData: rows,
      refetch,
      isFetching,
    } = useGetProjectsEvaluateQuery({ id: idProjectEvaluate || "" });

    if (rows) {
      userPublish = verifyPublish(user, rows);
    }

    const handleSumbitEvaluateCreate = async () => {
      const scoreInit: InterfaceScore = {
        collectionMaterial: -1,
        conclusions: -1,
        presentation: -1,
        procedureReport: -1,
        sources: -1,
        comment: "",
      };
      const request: InteraceRequestEvaluate = {
        ...scoreInit,
        project: idProjectEvaluate as string,
        //userToChange :user._id
      } as InteraceRequestEvaluate;
      try {
        const responseEvaluate = await putEvaluated(request).unwrap();
        if (Object.entries(responseEvaluate).length > 0) {
          //console.log("xq entraste", responseEvaluate);
          dispatch(
            activeAlertAction({
              isActive: true,
              isMode: "success",
              isText: t("zAdmin.components.form.tableEvaluations.create-eva"),
            })
          );
          dispatch(
            saveAction({
              evaluateScore: scoreInit,
              idProject: idProjectEvaluate as string,
              title: rows ? rows.title : "",
              email: user.email,
              id: user._id,
            })
          );
          const evaluate = verifyPublish(
            user,
            responseEvaluate as unknown as InterfaceEvaluationsResponse
          ) as InterfaceEvaluationsResponse;
          navigate(
            `/score/${idProjectEvaluate as string}/${evaluate.data[0].id}`
          );
        } else {
          return dispatch(
            activeAlertAction({
              isActive: true,
              isMode: "error",
              isText: t("zAdmin.components.form.tableEvaluations.eva-error"),
            })
          );
        }
      } catch (error) {
        //console.log(error, "Cual es el error");
        return dispatch(
          activeAlertAction({
            isActive: true,
            isMode: "error",
            isText: t("zAdmin.components.form.tableEvaluations.eva-error"),
          })
        );
      }
    };

    return (
      <>
        <TableContainer
          sx={{
            height: "90%",
            width: "90%",
            margin: "auto",
            marginTop: "2%",
            padding: "2%",
            maxHeight: props.maxHeight,
          }}>
          {/* Title + reverse */}
          <DoubleContainer
            gridTemplateColumns="auto"
            display="grid"
            width="100%">
            <NavbarLink paddingLeft="0" to="#" onClick={() => window.history.back()}>
              {" "}
              <ReturnIco /> 
              {t("zAdmin.components.form.tableEvaluations.back")}{" "}
            </NavbarLink>
            <TitleWrapper padding="0 0 2% 0">
              {`${title} de ${rows ? rows.title : ""}`}{" "}
            </TitleWrapper>
          </DoubleContainer>

          {/* Button actions  */}
          {!userPublish && (
            <ButtonComponent
              paddingLeft="1%"
              width="auto"
              heigth="45px"
              className="btn-hover"
              type="button"
              onClick={() => handleSumbitEvaluateCreate()}
            >
              {"Evaluar"}
            </ButtonComponent>
          )}

          {/* Content Tabla */}
          <TableContent colum={colum} rows={rows} />

          {/* Loading */}
          <Loading open={isLoading || isFetching} />
        </TableContainer>
      </>
    );
  }
);

interface InterfaceTableContentEvaluate {
  rows: InterfaceEvaluationsResponse | undefined | false;
  colum: InterfaceColumnBase[];
}

const TableContent = memo(({ colum, rows }: InterfaceTableContentEvaluate) => {
  const [t, i18n] = useTranslation("global");
  //navigate
  const navigate = useNavigate();

  //params
  const { idProjectEvaluate } = useParams();

  const evaluation = useAppSelector(selectEvaluate);

  const dispatch = useAppDispatch();

  let sumWithInitial = rows
    ? rows.data.reduce(
      (previousValue, currentValue) => previousValue + currentValue.score,
      0
    )
    : 0;
  sumWithInitial = sumWithInitial
    ? parseFloat((sumWithInitial / (rows ? rows.data.length : 1)).toFixed(2))
    : 0;
  const handleSumbitEvaluateEdit = (row: InterfaceDataEvaluated) => {
    dispatch(
      editAction({
        evaluateScore: row.evaluation,
        idProject: idProjectEvaluate as string,
        title: rows ? rows.title : "",
        email: row.email,
        id: row.id,
      })
    );
    navigate(`/score/${idProjectEvaluate as string}/${row.id}`);
  };

  const handleSumbitEvaluateRead = (row: InterfaceDataEvaluated) => {
    const { evaluation: score, email, id } = row;
    const status = statusEvaluation(score as InterfaceEvaluation);

    //console.log(status);

    if (status === "Completada") {
      dispatch(
        completedAction({
          evaluateScore: score,
          idProject: idProjectEvaluate as string,
          title: rows ? rows.title : "",
          email: email,
          id: id,
        })
      );
    } else if (!((idProjectEvaluate as string) in evaluation.evaluate)) {
      {
        dispatch(
          saveAction({
            evaluateScore: score,
            idProject: idProjectEvaluate as string,
            title: rows ? rows.title : "",
            email: row.email,
            id,
          })
        );
      }
    } else {
      const evaluatedTest = evaluation.info[idProjectEvaluate as string];
      if (evaluatedTest && evaluatedTest.completed) {
        dispatch(
          saveAction({
            evaluateScore: score,
            idProject: idProjectEvaluate as string,
            title: rows ? rows.title : "",
            email: row.email,
            id,
          })
        );
      }
    }

    navigate(`/score/${idProjectEvaluate as string}/${row.id}`);
  };

  return (
    <>
      <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
        <TableHead>
          <TableRow>
            {colum.map((items) => (
              <TableCell
                key={items.id}
                align={items.align}
                style={{
                  minWidth: items.minWidth,
                  fontWeight: items.fontWeight,
                }}
              >
                {items.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {rows && rows.data.length != 0 ? (
            rows.data.map((row, index) => (
              <TableRow key={row.createdAt}>
                <TableCell
                  style={{
                    width: 160,
                    cursor: "pointer",
                    textDecorationLine: "underline",
                  }}
                  align="center"
                  onClick={() => handleSumbitEvaluateRead(row)}
                >
                  {row.status === "Completada"
                    ? t("zAdmin.components.form.tableEvaluations.see-eva")
                    : t("zAdmin.components.form.tableEvaluations.continue-eva")}
                </TableCell>
                <TableCell
                  style={{ width: 160, cursor: "default" }}
                  align="center"
                >
                  {row.email}
                </TableCell>
                <TableCell
                  style={{ width: 160, cursor: "default" }}
                  align="center"
                >
                  {row.createdAt}
                </TableCell>
                <TableCell
                  style={{ width: 160, cursor: "default" }}
                  align="center"
                >
                  {row.score}
                </TableCell>
                <TableCell align="center" style={{ cursor: "default" }}>
                  {row.status === "Completada"
                    ? t("zAdmin.utils.evaluate.complete")
                    : t("zAdmin.utils.evaluate.pen-eva")}
                </TableCell>
                {row.status === "Completada" && (
                  <TableCell
                    style={{ width: 160, cursor: "pointer" }}
                    align="center"
                    onClick={() => handleSumbitEvaluateEdit(row)}
                  >
                    <TiEditIcon />
                  </TableCell>
                )}
              </TableRow>
            ))
          ) : (
            <TableCell style={{ cursor: "default" }} align="center" colSpan={6} sx={{ py: 3 }}>
              {t("zAdmin.components.form.tableEvaluations.no-evaluation")}
            </TableCell>
          )}
          <TableCell
            align="center"
            style={{
              width: 160,
              cursor: "default",
            }}
            colSpan={7}
            sx={{ py: 2 }}
          >
            {t("zAdmin.components.form.tableEvaluations.prom-total")}{" "}
            {sumWithInitial}
          </TableCell>
        </TableBody>
      </Table>

      {/* { <Loading open={isLoading} /> } */}
    </>
  );
});
