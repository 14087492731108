import {
    InterfaceProject,
    InterfaceProjectLarge,
} from "../../interfaces/project";
import { dateComplet, rolesViews } from "../../utils/utils";
import {
    InterfaceRoleUser,
    TypeRoleUser,
    InterfaceDataUser,
} from "../../interfaces/user";
import { TFunction } from "react-i18next";

export const projectsActiveConvocations = (
    dataProject: InterfaceProject[]
): InterfaceProject[] => {
    let dataPrint: InterfaceProject[] = [];

    for (let i = 0; dataProject.length > i; i++) {
        const a = dataProject[i];

        if (a.announcement && a.announcement.active) {
            dataPrint.push(a);
        }
    }
    return dataPrint;
};

export const rolesChangues = (
    roles: InterfaceRoleUser[],
    t: TFunction<"global", undefined>
): InterfaceRoleUser[] => {
    let dataPrint: InterfaceRoleUser[] = [];

    for (let i = 0; roles.length > i; i++) {
        const a = { ...roles[i] };
        const role = rolesViews(a, t);
        if (role != "Sin rol") {
            a.name = role as TypeRoleUser;
            dataPrint.push(a);
        }
    }
    return dataPrint;
};

interface InterfaceKeyDinamic {
    [key: string]: unknown;
}

export const filterDataUsers = (dataUser: InterfaceDataUser, t: TFunction<"global", undefined>) => {
    const objectEnd: InterfaceKeyDinamic = {};

    for (let [key, value] of Object.entries(dataUser)) {
        const keyUser = key as keyof InterfaceDataUser;

        if (
            keyUser === "firstName" ||
            keyUser === "email" ||
            keyUser === "createdAt" ||
            keyUser === "active" ||
            keyUser === "records" ||
            keyUser === "role"
        ) { 
            ///console.log(keyUser)
            if (keyUser === "createdAt") {

                //console.log(dateComplet(value))
                value = dateComplet(value);
            } else if (keyUser === "active") {
                value = value ? t("zAdmin.utils.users.active") : t("zAdmin.utils.users.inactive");
            } else if (keyUser === "records") {
                if (value) {
                    //console.log(value)
                    /*value = value.reduce(
                            //previos: InterfaceProjectLarge,
                            //current: InterfaceProjectLarge
                            previos: InterfaceProject,
                            current: InterfaceProject
                        ) => {
                            let nameProjectP = "";
                            let nameProjectC = "";
                            if (previos) {
                                nameProjectP = previos.project.name;
                            }

                            if (current) {
                                nameProjectC = current.project.name;
                            }
                            return `${nameProjectP} ${nameProjectC}`;
                        },
                        ""
                    );*/
                } else {
                    value = "";
                }
            } else if (keyUser === "role"){
                //console.log(value)
            }
                else if (keyUser === "firstName") {
                value = `${value} ${dataUser["lastName"]}`;
            }
            objectEnd[key] = value;
        }

        // if (typeof value != "string") {
        //     continue;
        // }
    }
    return objectEnd;
};
