//React Modules
import { memo, useRef, useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { InputAdornment, IconButton } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { ArrowUpward, ArrowDownward } from '@material-ui/icons';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TableHead,
    TextField,
    DialogContent,
    DialogActions,
} from "@mui/material";

// Interfaces

import {
    InterfaceTableBase,
    InterfaceTableForm,
    InterfaceTableContent,
    InterfaceTableContentFilter,
} from "../../interfaces/table";
import { InterfaceProject } from "../../../interfaces/project";

//translate
import { useTranslation } from "react-i18next";

//styles
import { StyledMultiline } from "../../styles/materialUi";

import {
    ButtonComponent,
    DoubleContainer,
    TitleWrapper,
} from "../../../styles/globalComponents";
import {
    LabelInput,
    SubLabelInput,
    WrapperInput,
} from "../../../zUsers/styles/Input";

//Components
import { AutocompleteComponent } from "../../../components/autocomplete";
import { TablePaginationGeneral } from "../paginate";
import { Loading } from "../../../components/loading";
import { ModalGeneric } from "../../../components/modalGeneric";

//hooks
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import { useForm } from "../../../hooks/form";

//data
import { stateProject } from "../../data/project";

//api
import { usePutDeactivateMutation } from "../../../zUsers/api/auth";
import { useGetProjectsAsignationsQuery } from "../../api/project";

//utils
import {
    dateComplet,
    dateYears,
    filesProjectState,
} from "../../../utils/utils";
import { mediaTotal } from "../../utils/evaluate";
import { selectUser } from "../../../zUsers/slices/auth";
import { InterfaceTableContentAsignations } from "../../interfaces/asignations";
import { filterDataProjects } from "../../utils/projects";

export const TableProjectJuryForm = memo(
    ({ title, colum, button,props }: InterfaceTableBase): JSX.Element => {
        const [t, i18n] = useTranslation("global");
        //form hooks
        const user = useAppSelector(selectUser);

        const cacheProject = user.records[user.records.length - 1];

        const idProjectCache = user._id;

        const { handleChange, page, data,rowsPerPage, search, count } = useForm<
            InterfaceTableForm<InterfaceProject>
        >({
            count: 0,
            page: 0,
            rowsPerPage: 25,
            search: "",
            filter1: "",
            filter2: "",
            data: [],
        });

        const {
            isLoading,
            currentData: rows,
            refetch,
            isFetching,
        } = useGetProjectsAsignationsQuery({
            skip: rowsPerPage * page,
            limit: rowsPerPage,
            id: idProjectCache,
        });

        //filter search all keys
        const requestSearch = (
            searchedVal: string,
            key?: keyof InterfaceProject
        ) => {
            const filteredRows = rows
                ? rows.result.filter((row) => {
                      if (key) {
                          if (key === "jurors") {
                              if (row.jurors) {
                                  for (const itemsRecords of row.jurors) {
                                      return itemsRecords.firstName
                                          .toLowerCase()
                                          .includes(searchedVal.toLowerCase());
                                  }
                              }
                          } else if (key === "announcement") {
                            if (row.announcement){
                                const dateAndName =
                                    row.announcement.name +
                                    " " +
                                    dateYears(row.announcement.initialDate);
                                return dateAndName
                                    .toLowerCase()
                                    .includes(searchedVal.toLowerCase());
                            } else {
                                return false
                            }
                          }
                      } else {
                          for (const [key, value] of Object.entries(
                              filterDataProjects(row, t)
                          )) {
                              const valueString = value as string;
                              if (
                                  valueString
                                      .toLowerCase()
                                      .includes(searchedVal.toLowerCase())
                              ) {
                                  return true;
                              } else {
                                  continue;
                              }
                          }

                          return false;
                      }
                  })
                : [];
            handleChange({ field: "search", value: searchedVal });
            handleChange({ field: "data", value: filteredRows });
        };

        const cancelSearch = () => {
            handleChange({ field: "search", value: "" });
            requestSearch(search);
        };

        if (rows && rows.count) {
            if (count != rows.count) {
                handleChange({ field: "count", value: rows.count });
            }
        }

        useEffect(() => {
            if (rows) {
                handleChange({ field: "data", value: rows.result });
            }
            //console.log(idProjectCache);

            //console.log(user._id);
            refetch();

        }, [rows]);

        return (
            <>
                <TableContainer
                    sx={{
                        height: "90%",
                        width: "90%",
                        margin: "auto",
                        marginTop: "2%",
                        padding: "2%",
                        maxHeight: props.maxHeight
                    }}
                >
                    {/* Title + reverse */}
                    <DoubleContainer
                        gridTemplateColumns="auto"
                        display="grid"
                        width="100%"
                    >
                        <TitleWrapper padding="0 0 2% 0">
                            {" "}
                            {title}{" "}
                        </TitleWrapper>
                    </DoubleContainer>

                    {/* Button actions  */}
                    {button}

                    {/* Filters */}
                    <DoubleContainer alingItems="baseline">
                        {/* Search  */}
                        <DoubleContainer>
                            <WrapperInput heigth={"100"} width="100%">
                                <TextField
                                    placeholder={title}
                                    size={"small"}
                                    onChange={(e) => {
                                        const value = e.target.value.trim();
                                        if (value === "") {
                                            cancelSearch();
                                        } else {
                                            requestSearch(value);
                                        }
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                <IconButton>
                                                    <SearchIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </WrapperInput>
                        </DoubleContainer>
                    </DoubleContainer>

                    {/* Content Tabla */}
                    <TableContent colum={colum} rows={data} />

                    {/* Paginate */}
                    <TablePaginationGeneral
                        page={count <= 0 ? 0 : page}
                        rowsPerPage={rowsPerPage}
                        onChange={handleChange}
                        count={count}
                    />

                    {/* Loading */}
                    <Loading open={isLoading || isFetching} />
                </TableContainer>
            </>
        );
    }
);

const TableContent = memo(
    ({ colum, rows }: InterfaceTableContentFilter<InterfaceProject>) => {
        //navigate
        const navigate = useNavigate();

        //ref
        const rowData = useRef({} as InterfaceProject);

        //event
        const dispatch = useAppDispatch();

        const [t, i18n] = useTranslation("global");

        //state
        const [changeStateP, setChangeStateP] = useState(false);
        const [valueStateProject, setValueStateProject] = useState("");
        //actions menu
        const [openSendEmailIndividual, setOpenSendEmailIndividual] =
            useState(false);
        //api
        const [putDeactivate, { isLoading: isLoadingDeactivate }] =
            usePutDeactivateMutation();

            const [isLoadingSorting, setLoadingSorting] = useState(false);

            //Constantes para el tema del ordenador por campos
            const [sortField, setSortField] = useState("");
            const [sortDirection, setSortDirection] = useState("asc");
    
    
            const compareStrings = (a: string, b: string, direction: string) => {
                if (a < b) {
                    return direction === 'asc' ? -1 : 1;
                }
                if (a > b) {
                    return direction === 'asc' ? 1 : -1;
                }
    
                return 0;
            };
    
            const compareNumber = (a: number, b: number, direction: string) => {
                if (direction === 'asc') {
                    return a - b
                } else {
                    return b - a
                }
            }
    
            const compareByField = (field: string, a: InterfaceProject, b: InterfaceProject, direction: string) => {
    
                //console.log(field)
    
                switch (field) {
                    case 'name':
                        return compareStrings(a.name || '', b.name || '', direction);
                    case 'emailRepresent':
                        return compareStrings(
                            a.representative && a.representative.email ? a.representative.email : '',
                            b.representative && b.representative.email ? b.representative.email : '',
                            direction
                        );
                    case 'state':
                        return compareStrings(a.status || '', b.status || '', direction);
                    case 'stateActive':
                        return a.active === b.active ? 0 : (a.active ? 1 : -1);
                    case 'media':
                        var mediaA = mediaTotal(a.evaluations) !== null ? mediaTotal(a.evaluations) : 0;
                        var mediaB = mediaTotal(b.evaluations) !== null ? mediaTotal(b.evaluations) : 0;
    
                        return compareNumber(mediaA, mediaB, direction)
                    case 'createdAt':
                        return compareStrings(a.createdAt || '', b.createdAt || '', direction);
                    case 'convocations':
                        return compareStrings(a.announcement.name || '', b.announcement.name || '', direction);
                    case 'statusDelivery':
                        return compareStrings(a.statusDelivery || '', b.statusDelivery || '', direction);
                    default:
                        return 0;
                }
            };
    
            const sortedData = [...rows].sort((a, b) => {
                return compareByField(sortField, a, b, sortDirection);
            });
    
    
            const renderSortIcon = (field: string) => {
                if (field === sortField) {
                    return sortDirection === 'asc' ? <ArrowUpward /> : <ArrowDownward />;
                }
    
                return null;
            };
    
            //Ordenado
            const handleSort = (field: string) => {
                setLoadingSorting(true);
    
                // Verifica si el mismo campo se está ordenando nuevamente para cambiar la dirección
                const direction = field === sortField && sortDirection === "asc" ? "desc" : "asc";
    
                //console.log(rows.length)
    
                setTimeout(() => {
    
                    //console.log(field)
                    setSortField(field); // Actualiza el campo actualmente ordenado
    
                    setSortDirection(direction);
                    // Actualiza la dirección de ordenamiento
    
                    setLoadingSorting(false);
    
                }, 500)
    
    
            };




        return (
            <>
                <Table
                    sx={{ minWidth: 500 }}
                    aria-label="custom pagination table"
                >
                    <TableHead>
                        <TableRow>
                            {colum.map((items) => (
                                <TableCell
                                    key={items.id}
                                    align={items.align}
                                    style={{
                                        minWidth: items.minWidth,
                                        fontWeight: items.fontWeight,
                                        cursor: "pointer",
                                    }}
                                    onClick={() => handleSort(items.id)}
                                >
                                    {renderSortIcon(items.id)}
                                    {items.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {rows &&
                            rows.map((row, index) => (
                                <TableRow key={row._id}>
                                    <TableCell
                                        style={{
                                            width: 160,
                                            textDecorationLine: "underline",
                                            cursor: "pointer"
                                        }}
                                        align="center"
                                        onClick={() =>
                                            navigate(`/project/${row._id}`)
                                        }
                                    >
                                        {row.name}
                                    </TableCell>
                                    <TableCell
                                        style={{ width: 160 }}
                                        align="center"
                                    >
                                        {row.status}
                                    </TableCell>
                                    <TableCell
                                        style={{ width: 160 }}
                                        align="center"
                                    >
                                        {mediaTotal(row.evaluations)}
                                    </TableCell>
                                    <TableCell
                                        style={{ width: 160 }}
                                        align="center"
                                    >
                                        {dateComplet(row.createdAt)}
                                    </TableCell>
                                    <TableCell
                                        style={{ width: 160 }}
                                        align="center"
                                    >
                                        {filesProjectState(row, t)}
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            width: 160,
                                            textDecorationLine: "underline",
                                            cursor: "pointer",
                                        }}
                                        align="center"
                                        onClick={() =>
                                            navigate(`/evaluations/${row._id}`)
                                        }
                                    >
                                        {"ver evaluaciones"}
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
                {/* send email a people project */}
                <ModalGeneric
                    isOpen={openSendEmailIndividual}
                    handleClose={() => setOpenSendEmailIndividual(false)}
                >
                    <DialogContent>
                        <WrapperInput>
                            <LabelInput htmlFor={"sendEmail"}>
                                {t(
                                    "zAdmin.components.form.tableProjectJury.send-email"
                                )}
                            </LabelInput>
                            <SubLabelInput>
                                {t(
                                    "zAdmin.components.form.tableProjectJury.text-email"
                                )}
                            </SubLabelInput>
                            <StyledMultiline
                                id="outlined-multiline-static"
                                style={{ width: "100%" }}
                                multiline
                                rows={4}
                                defaultValue={t(
                                    "zAdmin.components.form.tableProjectJury.info-text"
                                )}
                            />
                        </WrapperInput>
                    </DialogContent>
                    <DialogActions style={{ padding: "2%" }}>
                        <ButtonComponent className="btn-hover" type="button">
                            {t("zAdmin.components.form.tableProjectJury.send")}
                        </ButtonComponent>
                    </DialogActions>
                </ModalGeneric>

                {/* change state project */}
                <ModalGeneric
                    isOpen={changeStateP}
                    handleClose={() => setChangeStateP(false)}
                >
                    <DialogContent>
                        <AutocompleteComponent
                            data={stateProject}
                            handle={(data: string) =>
                                setValueStateProject(data)
                            }
                            valueDefault={valueStateProject}
                            label={t(
                                "zAdmin.components.form.tableProjectJury.state-project"
                            )}
                            subLabel={t(
                                "zAdmin.components.form.tableProjectJury.state-project-text"
                            )}
                            placeholder={""}
                        />
                    </DialogContent>
                    <DialogActions style={{ padding: "2%" }}>
                        <ButtonComponent
                            className="btn-hover"
                            type="button"
                            onClick={() => console.log("no")}
                        >
                            {t(
                                "zAdmin.components.form.tableProjectJury.modify-state"
                            )}
                        </ButtonComponent>
                    </DialogActions>
                </ModalGeneric>
                {/* Loading */}
                <Loading open={isLoadingDeactivate} />
            </>
        );
    }
);
