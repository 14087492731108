//Style
import {
    Container,
    ButtonComponent,
    TitleWrapper,
} from "../../../zMembers/styles/member";

import { Wrapper, DoubleContainer } from "../../../zMembers/styles/member";
import { NavbarLink } from "../../../styles/nav";
import { Texto } from "../../styles/member";
import { Grid, Alert, Collapse, Skeleton, Stack } from "@mui/material";

//data

//translate
import { useTranslation } from "react-i18next";

//React
import { useState, useRef } from "react";
import { InputComponent } from "../../../zUsers/components/Input";
import React from "react";

//Hooks
import { useForm } from "react-hook-form";
import { useFormHooks } from "../../../zUsers/hooks/login";

//modules react
import { Link as RouteLink, useParams } from "react-router-dom";
import Link from "@mui/material/Link";

//Interfaces
import { InterfaceRegisterProps } from "../../../zUsers/interfaces/register";
import { InterfaceLogin } from "../../../zMembers/interfaces/member";
import { interfaceHomeRenderStyle } from "../../interfaces/member";

import { MemberList } from "../membersList";
import { selectUser } from "../../../zUsers/slices/auth";
import {
    InterfaceProject,
    InterfaceProjectLarge,
} from "../../../interfaces/project";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";

//apis
//api
import { Loading } from "../../../components/loading";
import {
    useGetProjectsMembersQuery,
    usePostInvitedUserMutation,
} from "../../api/member";
import { selectRole } from "../../../zUsers/slices/role";
import { rolesViews } from "../../../utils/utils";
import { yupResolver } from "@hookform/resolvers/yup";
import { schemaRecovery } from "../../../zUsers/validators/schemaRecovery";
import { activeAlertAction } from "../../../slices/alerts";
import { InterfaceRoleUser } from "../../../interfaces/user";
import { ReturnIco } from "../../../styles/globalComponents";

interface InterfaceMemberEmail {
    email: string;
}

export const Member = ({ props }: interfaceHomeRenderStyle) => {
    const [t, i18n] = useTranslation("global");
    const user = useAppSelector(selectUser);

    //roles
    const roles = useAppSelector(selectRole);

    const userRole = user.role as InterfaceRoleUser;
    const conditionStudent =
        userRole.name === "student" || userRole.name === "tutor";

    const cacheProject = user.records[user.records.length - 1];

    const { idMember } = useParams();
    const { idProject } = useParams();


    /*const idProjectCache = idMember? idMember:
        "project" in cacheProject
            ? typeof cacheProject.project != "string"
                ? cacheProject.project._id
                : cacheProject.project
            : cacheProject._id;*/

    const idProjectCache = idMember != null ? idMember :
        cacheProject && "project" in cacheProject ?
            typeof cacheProject.project != "string" ?
                cacheProject.project._id :
                cacheProject.project
            : cacheProject && cacheProject._id ? cacheProject._id : idProject;
            

    //apis
    const { isLoading, currentData: data } = useGetProjectsMembersQuery({
        id: idProjectCache,
    });

    const [postInvite, { isLoading: isLoadingUser }] =
        usePostInvitedUserMutation();

    const [invite, setInvite] = useState<boolean>(false);

    const [AlertSuccess, setAlertSuccess] = useState(false);
    const [AlertError, setAlertError] = useState(false);

    const roleInvited = useRef("");

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm<InterfaceMemberEmail>({
        defaultValues: { email: "" },
        resolver: yupResolver(schemaRecovery(t)),
    });
    //event
    const dispatch = useAppDispatch();

    //handels
    const handleOnClick = (role: string) => {
        
           //console.log("Este es el rol:" + role);
        if (role === "student") {
            roleInvited.current = "student";
        } else {
            roleInvited.current = "tutor";
        }
        setValue("email", "");
        setInvite(!invite);
    };

    const handleInvitedUser = async (data: InterfaceMemberEmail) => {
        const roleStuden = roles.filter(
            (items) => items.name === roleInvited.current
        );

        const request = {
            role: roleStuden[0]._id,
            project: idProjectCache,
            email: data.email,
        };

        try {
            const responseRemoveStudent = await postInvite(request).unwrap();

            if ("response" in responseRemoveStudent) {
                return dispatch(
                    activeAlertAction({
                        isActive: true,
                        isMode: "error",
                        isText: t("zMembers.components.form.member.use-email"),
                    })
                );
            } else {
                dispatch(
                    activeAlertAction({
                        isActive: true,
                        isMode: "success",
                        isText: t("zMembers.components.form.member.user-success"),
                    })
                );
            }
        } catch (error) {
            dispatch(
                activeAlertAction({
                    isActive: true,
                    isMode: "error",
                    isText: t("zMembers.components.form.member.user-error"),
                })
            );
        }
    };

    return (
        <>
            {data ? (
                <Container width={props.width} heigth={props.heigth}>
                    <Wrapper>
                        <DoubleContainer
                            gridTemplateColumns="auto"
                            display="grid"
                            width="100%"
                        >

                            <TitleWrapper fontSize="1.5em">
                                <RouteLink to="#" onClick={() => window.history.back()}>
                                    <Link color="black" underline="hover">
                                        <ReturnIco /> {t("zMembers.components.form.member.back")}
                                    </Link>
                                </RouteLink>
                            </TitleWrapper>

                            <TitleWrapper> {data.name} </TitleWrapper>
                            <Texto>
                                {" "}
                                {t("zMembers.components.form.member.edit-permissions")}{" "}
                            </Texto>
                        </DoubleContainer>

                        <DoubleContainer width="100%" padding=" 0 5% 0 5%">
                            <ButtonComponent
                                className="btn-hover"
                                type="button"
                                onClick={() => handleOnClick("student")}
                                disabled={conditionStudent}
                                background={conditionStudent ? "#DEDEDE" : ""}
                            >
                                {t("zMembers.components.form.member.inv-student")}
                            </ButtonComponent>

                            <ButtonComponent
                                className="btn-hover"
                                type="button"
                                onClick={() => handleOnClick("tutor")}
                                disabled={conditionStudent}
                                background={conditionStudent ? "#DEDEDE" : ""}
                            >
                                {t("zMembers.components.form.member.inv-tutor")}
                            </ButtonComponent>
                        </DoubleContainer>

                        <Grid container>
                            <Grid
                                item
                                xs={12}
                                paddingLeft="5%"
                                paddingRight="5%"
                            >
                                {invite && (
                                    <>
                                        <Collapse
                                            in={AlertSuccess}
                                            style={{ paddingTop: "3%" }}
                                        >
                                            <Alert
                                                onClose={() =>
                                                    setAlertSuccess(false)
                                                }
                                                severity="success"
                                            >
                                                {t("zMembers.components.form.member.close-me")}
                                            </Alert>
                                        </Collapse>
                                        <Collapse
                                            in={AlertError}
                                            style={{ paddingTop: "3%" }}
                                        >
                                            <Alert
                                                onClose={() =>
                                                    setAlertError(false)
                                                }
                                                severity="error"
                                            >
                                                {t("zMembers.components.form.member.close-me")}
                                            </Alert>
                                        </Collapse>
                                        <form
                                            onSubmit={handleSubmit(
                                                handleInvitedUser
                                            )}
                                            style={{ padding: 0 }}
                                        >
                                            <InputComponent
                                                {...register("email")}
                                                label={"Email"}
                                                errors={errors.email}
                                                placeholder={
                                                    t("zMembers.components.form.member.enter-email")
                                                }
                                                type={"email"}
                                            />

                                            <Grid item xs={3}>
                                                <ButtonComponent
                                                // onClick={handleSubmit}
                                                >
                                                    {t("zMembers.components.form.member.invite")}
                                                </ButtonComponent>
                                            </Grid>
                                        </form>
                                    </>
                                )}
                            </Grid>
                        </Grid>
                        {data && data.representative && (
                            <MemberList
                                email={data.representative.email}
                                role={t("zMembers.components.form.member.representative")}
                                idUser={data.representative._id}
                            />
                        )}
                        {data && data.tutors && (

                            data.tutors.map((items) => {
                                const idRole = items.role as string;
                                let nameRole = "";
                                for (let i = 0; roles.length > i; i++) {
                                    if (roles[i]._id === idRole) {
                                        nameRole = rolesViews(roles[i], t);
                                        break;
                                    }
                                }
                                return (
                                    <MemberList
                                        email={items.email}
                                        role={nameRole}
                                        idUser={items._id}
                                    />
                                );
                            })
                        )}

                        {data &&
                            data.students.map((items) => {
                                const idRole = items.role as string;
                                let nameRole = "";
                                for (let i = 0; roles.length > i; i++) {
                                    if (roles[i]._id === idRole) {
                                        nameRole = rolesViews(roles[i], t);
                                        break;
                                    }
                                }
                                return (
                                    <MemberList
                                        email={items.email}
                                        role={nameRole}
                                        idUser={items._id}
                                    />
                                );
                            })}
                        <div style={{ width: "100%" }}>
                            <br />
                        </div>
                    </Wrapper>
                    <Loading open={isLoading || isLoadingUser} />
                </Container>
            ) : (
                <>
                    <Container width={props.width} heigth={props.heigth}>
                        <Wrapper>
                            <DoubleContainer
                                gridTemplateColumns="auto"
                                display="grid"
                                width="100%"
                            >
                                <Skeleton
                                    width={"20%"}
                                    height={30}
                                    style={{ margin: "0 5% 0 5%" }}
                                />

                                <Skeleton
                                    width={"20%"}
                                    height={30}
                                    style={{ margin: "0 5% 0 5%" }}
                                />
                            </DoubleContainer>
                            <DoubleContainer width="100%" padding=" 5% 5% 0 5%">
                                <Skeleton
                                    variant="rectangular"
                                    width={"100%"}
                                    height={60}
                                />

                                <Skeleton
                                    variant="rectangular"
                                    width={"100%"}
                                    height={60}
                                />
                            </DoubleContainer>
                            <form>
                                <Stack spacing={2}>
                                    <Skeleton
                                        variant="rectangular"
                                        width={"100%"}
                                        height={60}
                                    />
                                    <Skeleton
                                        variant="rectangular"
                                        width={"100%"}
                                        height={60}
                                    />
                                    <Skeleton
                                        variant="rectangular"
                                        width={"100%"}
                                        height={60}
                                    />
                                </Stack>
                            </form>
                        </Wrapper>
                    </Container>
                </>
            )}
        </>
    );
};
