//react
import { useState } from "react";
import {
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText,
} from "@mui/material/";

//styles
import {
    Container,
    DoubleContainer,
    Wrapper,
    TitleWrapper,
    ButtonComponent,
} from "../../styles/score";

import { ReturnIco } from "../../../styles/globalComponents";

import Grid from "@mui/material/Grid";
//elements
import { SlideFive, SlideTen, SlideTwenty } from "../slider";
import { UseFormControl } from "../comentaries";
import {
    PopoverPopupStateC,
    PopoverPopupStateF,
    PopoverPopupStateI,
    PopoverPopupStateO,
    PopoverPopupStateP,
    PopoverPopupStateR,
} from "../popover";

//interfaces
import {
    InteraceRequestEvaluate,
    interfaceHomeRenderStyle,
    InterfaceScore,
} from "../../interfaces/score";

import { NavbarLink } from "../../../styles/nav";

//icons
import { useNavigate, useParams } from "react-router-dom";
import {
    useDeleteProjectEvaluateMutation,
    usePutProjectEvaluateMutation,
} from "../../api/project";

//hooks
import { useAppDispatch } from "../../../hooks/redux";
import { useFormEvaluate } from "../../hooks/evaluate";
import { useAppSelector } from "../../../hooks/redux";

//translate
import { useTranslation } from "react-i18next"

//slices
import { activeAlertAction } from "../../../slices/alerts";
import {
    clearAction,
    completedAction,
    saveAction,
    selectEvaluate,
} from "../../slides/evaluate";
import { Loading } from "../../../components/loading";
import { InterfacePropsEvaluated } from "../../../interfaces/evaluate";
import { promediScore, statusEvaluation } from "../../utils/evaluate";
import { InterfaceEvaluation } from "../../../interfaces/project";
import { ModalGeneric } from "../../../components/modalGeneric";
import { Component404 } from '../../../views/404';
import { INITIAL_SCORE } from "../../data/evaluations";
import { selectUser } from "../../../zUsers/slices/auth";



export const Score = ({ props }: interfaceHomeRenderStyle) => {
    const [t, i18n] = useTranslation("global")

    const [openRemove, setOpenModalRemove] = useState(false);

    //params
    const { idPE, idEvaluate } = useParams();

    const evaluation = useAppSelector(selectEvaluate);

    const navigate = useNavigate();
    const scoreData = evaluation.evaluate[idPE as string] as InterfaceScore;
    const info = evaluation.info[idPE as string] as InterfacePropsEvaluated;


    const { handleChange, formulario } = useFormEvaluate(
        scoreData || INITIAL_SCORE,
        idPE as string,
        info ? info.email : '',
        info ? info.title : '',
        info ? info.id : '',
        saveAction
    );

    //apis
    const [putEvaluated, { isLoading }] = usePutProjectEvaluateMutation();
    const [deleteEvaluated, { isLoading: isLoadingDelete }] =
        useDeleteProjectEvaluateMutation();
    //events
    const dispatch = useAppDispatch();

    const user = useAppSelector(selectUser);

    const disable = info ? info.completed : true;

    const handleSumbit = async (infoState: boolean = false) => {
        const status = statusEvaluation(formulario as InterfaceEvaluation);

        const { collectionMaterial, comment, conclusions, presentation, procedureReport, sources } = formulario

        const score: InterfaceScore = {
            collectionMaterial,
            comment,
            conclusions,
            presentation,
            procedureReport,
            sources
        }
        if (status != "Completada") {
            return dispatch(
                activeAlertAction({
                    isActive: true,
                    isMode: "info",
                    isText: t("zAdmin.components.form.score.missing-data-info"),
                })
            );
        }
        const request: InteraceRequestEvaluate = {
            ...score,
            project: idPE as string
        } as InteraceRequestEvaluate;
        if (user._id != info.id) {
            request.userToChange = info.id
        }
        try {
            const responseEvaluate = await putEvaluated(request).unwrap();
            if (infoState) {
                dispatch(
                    clearAction({
                        idProject: idPE as string,
                    })
                );


                dispatch(
                    activeAlertAction({
                        isActive: true,
                        isMode: "success",
                        isText: t("zAdmin.components.form.score.correct-evaluation"),
                    })
                );
                return navigate(`/evaluations/${idPE}`)
            }
        } catch (error) {
            if (infoState) {
                return dispatch(
                    activeAlertAction({
                        isActive: true,
                        isMode: "error",
                        isText: t("zAdmin.components.form.score.error-evaluation"),
                    })
                );
            }
        }
    };

    const handleClickOpenRemove = async () => {
        setOpenModalRemove(false)
        //console.log("entro")
        const requestDelete = {
            author: idEvaluate as string,
            project: idPE as string,
        };
        try {
            const responseEvaluate = await deleteEvaluated(
                requestDelete
            ).unwrap();


            dispatch(
                activeAlertAction({
                    isActive: true,
                    isMode: "success",
                    isText: t("zAdmin.components.form.score.correct-elimination"),
                })
            );

            return navigate(`/evaluations/${idPE as string}`);
        } catch (error) {
            console.log(error, 'epale ')
            dispatch(
                activeAlertAction({
                    isActive: true,
                    isMode: "error",
                    isText: t("zAdmin.components.form.score.error-evaluation"),
                })
            );
        }
    };

    const isMine = user._id === idEvaluate;

    return (
        <>
            {info && scoreData ? (
                <Container
                    width={(props.width = "50%")}
                    paddingTop="5%"
                    heigth={props.heigth}
                >

                    <Wrapper>
                        <NavbarLink paddingLeft="0" to="#" onClick={() => window.history.back()}>
                            {" "}
                            <ReturnIco />
                            {t("zAdmin.components.form.score.back")}{" "}
                        </NavbarLink>

                        <TitleWrapper>
                            {t("zAdmin.components.form.score.evaluation-of")} "{info.title}" <br /> {t("zAdmin.components.form.score.user")}
                            {info.email} <br />
                            {t("zAdmin.components.form.score.points")}{" "}
                            {promediScore(formulario as InterfaceEvaluation, true)}
                            /55
                        </TitleWrapper>


                        {!info.completed && (
                            <DoubleContainer width="70%" padding=" 0 5% 0 5%">

                                <ModalGeneric
                                    isOpen={openRemove}
                                    handleClose={() => setOpenModalRemove(false)}

                                >
                                    <DialogTitle>{t("zAdmin.components.form.score.delete")}</DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-slide-description">
                                            {
                                                t("zAdmin.components.form.score.ask-delete-evaluation")
                                            }
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Container
                                            width="100%"
                                            padding="0 5% 5% 5%"
                                            paddingLeft="5%"
                                        >
                                            <ButtonComponent
                                                className="btn-hover"
                                                type="button"
                                                onClick={handleClickOpenRemove}
                                            >
                                                {t("zAdmin.components.form.score.delete")}
                                            </ButtonComponent>
                                        </Container>
                                    </DialogActions>
                                </ModalGeneric>

                            </DoubleContainer>
                        )}

                        <Container
                            gridTemplateColumns="auto"
                            display="grid"
                            width="100%"
                            padding="5% 5% 0 5%"
                        >
                            <Grid container style={{ margin: "3% 0 3% 0" }}>
                                <Grid item xs={11}>
                                    {t("zAdmin.components.form.score.procedure-report")}
                                </Grid>
                                <PopoverPopupStateI />
                            </Grid>
                            <SlideTen
                                disable={!isMine}
                                handleChange={handleChange}
                                name={"procedureReport"}
                                value={formulario.procedureReport}
                            />
                        </Container>

                        <Container
                            gridTemplateColumns="auto"
                            display="grid"
                            width="100%"
                            padding=" 0 5% 0 5%"
                        >
                            <Grid container style={{ margin: "3% 0 3% 0" }}>
                                <Grid item xs={11}>
                                    {t("zAdmin.components.form.score.compilation-material")}
                                </Grid>
                                <PopoverPopupStateR />
                            </Grid>
                            <SlideTen
                                disable={!isMine}
                                handleChange={handleChange}
                                name={"collectionMaterial"}
                                value={formulario.collectionMaterial}
                            />
                        </Container>

                        <Container
                            gridTemplateColumns="auto"
                            display="grid"
                            width="100%"
                            padding=" 0 5% 0 5%"
                        >
                            <Grid container style={{ margin: "3% 0 3% 0" }}>
                                <Grid item xs={11}>
                                    {t("zAdmin.components.form.score.sources-interpretation")}
                                </Grid>
                                <PopoverPopupStateF />
                            </Grid>
                            <SlideTwenty
                                disable={!isMine}
                                handleChange={handleChange}
                                name={"sources"}
                                value={formulario.sources}
                            />
                        </Container>

                        <Container
                            gridTemplateColumns="auto"
                            display="grid"
                            width="100%"
                            padding=" 0 5% 0 5%"
                        >
                            <Grid container style={{ margin: "3% 0 3% 0" }}>
                                <Grid item xs={11}>
                                    {t("zAdmin.components.form.score.opinions-conclusions")}
                                </Grid>
                                <PopoverPopupStateO />
                            </Grid>
                            <SlideTen
                                disable={!isMine}
                                handleChange={handleChange}
                                name={"conclusions"}
                                value={formulario.conclusions}
                            />
                        </Container>

                        <Container
                            gridTemplateColumns="auto"
                            display="grid"
                            width="100%"
                            padding=" 0 5% 0 5%"
                        >
                            <Grid container style={{ margin: "3% 0 3% 0" }}>
                                <Grid item xs={11}>
                                    {t("zAdmin.components.form.score.presentation")}
                                </Grid>
                                <PopoverPopupStateP />
                            </Grid>
                            <SlideFive
                                disable={!isMine}
                                handleChange={handleChange}
                                name={"presentation"}
                                value={formulario.presentation}
                            />
                        </Container>

                        <Container
                            gridTemplateColumns="auto"
                            display="grid"
                            width="100%"
                            padding=" 0 5% 0 5%"
                        >
                            <Grid container style={{ margin: "3% 0 3% 0" }}>
                                <Grid item xs={11}>
                                    {t("zAdmin.components.form.score.jury-conclusion")}
                                </Grid>
                                <PopoverPopupStateC />
                            </Grid>
                        </Container>

                        <Container padding="5%" paddingTop="3%">
                            <UseFormControl
                                disable={!isMine}
                                handleChange={handleChange}
                                name={"comment"}
                                value={formulario.comment}
                            />
                        </Container>

                        {(!info.completed && isMine) && (
                            <Container
                                width="90%"
                                padding="0 5% 3% 0" 
                                style={{ display: "flex", justifyContent: "space-between" }}
                            >
                                <ButtonComponent
                                    className="btn-hover"
                                    type="button"
                                    onClick={() => setOpenModalRemove(!openRemove)}
                                >
                                    {t("zAdmin.components.form.score.delete-evaluation")}
                                </ButtonComponent>
                                <ButtonComponent
                                    className="btn-hover"
                                    type="button"
                                    padding="3% 0 3% 0"
                                    onClick={() => handleSumbit(true)}
                                >
                                    {info.edit
                                        ? t("zAdmin.components.form.score.edit-evaluation")
                                        : t("zAdmin.components.form.score.save-evaluation")}
                                </ButtonComponent>
                            </Container>
                        )}
                    </Wrapper>
                </Container>
            ) : <Component404 />}

            <Loading open={isLoading || isLoadingDelete} />
        </>
    );
};
